/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-extra-parens */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useSpring } from 'react-spring';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  BackgroundSilver,
  BackgroundDarken,
  BackgroundButterscotch,
} from '../../components/components';
import { ButtonOutlined } from '../../components/buttons/index';
import { IGatsbyImageDataProps } from '../../types/image-types';
import usePlausible from '../../hooks/use-plausible';
import {
  Container,
  ContactHeadline,
  ContentContainer,
  ButtonContainer,
  Subline,
  SatelliteDiv,
} from './components';

type ContactSectionProps = {
  dark?: boolean,
  trackingId: string,
};

type QueryData = {
  allContentfulSection: {
    nodes: {
      title: string,
      subtitle: string,
      image: {
        file: {
          url: string,
        },
      },
      buttons: {
        title: string,
        link: string,
      }[],
    }[],
  },
  satellite: IGatsbyImageDataProps,
};

const calc = (x: number, y: number) => [(x - window.innerWidth) / 2, (y - window.innerHeight) / 2];
const trans = (x: number, y: number) => `translate3d(${x / 10}px,${y / 10}px,0)`;

const ContactSection: React.FC<ContactSectionProps> = ({ dark, trackingId }) => {
  const { plausible } = usePlausible();

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const {
    satellite,
    allContentfulSection,
  }: QueryData = useStaticQuery(
    graphql`
      query {
        satellite: file(relativePath: { eq: "satellite.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100,
              width: 400,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
        allContentfulSection(filter: {title: {eq: "Erde an typedigital"}}) {
          nodes {
            title
            subtitle
            image {
              file {
                url
              }
            }
            buttons {
              title
              link
            }
          }
        }
      }
    `,
  );

  const { nodes: [{ title, subtitle, buttons }] } = allContentfulSection;
  const component = dark ?
    (
      <BackgroundButterscotch>
        <BackgroundDarken>
          <Container
            onMouseMove={({ clientX: x, clientY: y }) => set.start({ xy: calc(x, y) })}
          >
            <SatelliteDiv
              style={{ transform: props.xy.to(trans) }}
            >
              <GatsbyImage
                image={getImage(satellite.childImageSharp.gatsbyImageData)!}
                alt='satellite'
              />
            </SatelliteDiv>
            <ContentContainer>
              <ContactHeadline>{title}</ContactHeadline>
              <Subline>{subtitle}</Subline>
              <ButtonContainer>
                <ButtonOutlined
                  type='button'
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    window.location.href = `tel:${buttons[1].link.replace('Telefon: ', '')}`;
                    plausible(`${trackingId}-button-anrufen`);
                  }}
                >
                  Anrufen
                </ButtonOutlined>
              </ButtonContainer>
            </ContentContainer>
          </Container>
        </BackgroundDarken>
      </BackgroundButterscotch>
    )
    : (
      <BackgroundSilver>
        <BackgroundDarken>
          <Container
            onMouseMove={({ clientX: x, clientY: y }) => set.start({ xy: calc(x, y) })}
          >
            <SatelliteDiv
              style={{ transform: props.xy.to(trans) }}
            >
              <GatsbyImage
                image={getImage(satellite.childImageSharp.gatsbyImageData)!}
                alt='satellite'
              />
            </SatelliteDiv>
            <ContentContainer>
              <ContactHeadline light>{title}</ContactHeadline>
              <Subline light>{subtitle}</Subline>
              <ButtonContainer>
                <ButtonOutlined
                  type='button'
                  primary
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    window.location.href = `tel:${buttons[0].link.replace('Telefon: ', '')}`;
                    plausible(`${trackingId}-button-anrufen`);
                  }}
                >
                  Anrufen
                </ButtonOutlined>
              </ButtonContainer>
            </ContentContainer>
          </Container>
        </BackgroundDarken>
      </BackgroundSilver>
    );
  return component;
};

export default ContactSection;
