import styled from 'styled-components';
import { animated } from 'react-spring';

import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  height: 100vh;
  place-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

const ContentContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-rows: repeat(3, minmax(0, auto));
  grid-template-columns: 1fr;
  ${breakpoints.xs`
    grid-gap: 20px;
  `}
  ${breakpoints.lg`
    grid-gap: 30px;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  ${breakpoints.xs`
    flex-direction: column;
    > * + * {
      margin: 20px 0 0 0;
    }
    `}
  ${breakpoints.lg`
    flex-direction: row;
    > * + * {
      margin: 0 0 0 40px;
    }
  `}
`;

const ContactHeadline = styled.h2<{light?: boolean}>`
  ${breakpoints.xs`
    width: 320px;
    font-size: 30px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 0.5px;
    text-align: center;
    margin-top: 100px;
  `}
  ${breakpoints.lg`
    width: 800px;
    font-size: 60px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.5px;
  `}
  ${breakpoints.xl`
    width: 1300px;
    font-size: 80px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.5px;
  `}
  text-shadow: 0 3px 15px rgba(50, 50, 50, 0.1);
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme, light }) => (light ? theme.color.basic.black : theme.color.basic.white)};
`;

const Subline = styled.h3<{light?: boolean}>`
  ${breakpoints.xs`
    width: 320px;
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 0.5px;
  `}
  ${breakpoints.lg`
    width: 800px;
    font-size: 30px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.5px;
  `}
  ${breakpoints.xl`
    width: 550px;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  `}
  text-shadow: 0 3px 15px rgba(50, 50, 50, 0.1);
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme, light }) => (light ? theme.color.basic.black : theme.color.basic.white)};
  text-align: center;
`;

const SatelliteDiv = styled(animated.div)`
  ${breakpoints.xs`
    width: 250px;
    position: absolute !important;
    bottom: 50vh;
    right: 15vw;
  `}
  ${breakpoints.sm`
    bottom: 50vh;
  `}
  ${breakpoints.md`
    bottom: 50vh;
  `}
  ${breakpoints.lg`
    width: 400px;
    position: absolute !important;
    right: 200px;
    bottom: 49vh;
  `}
`;

export {
  Container,
  ContentContainer,
  ButtonContainer,
  ContactHeadline,
  Subline,
  SatelliteDiv,
};
